import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {RuntimeInfo} from "../../resources/classes/RuntimeInfo";
import * as moment from "moment";
import {PatientItem} from "../../resources/classes/Patient/PatientItem";
import {ValidationController, ValidationControllerFactory, ValidationRules, Validator} from "aurelia-validation";
import {I18N} from "aurelia-i18n";

@autoinject
export class ReleasePatient {
    controller: DialogController;
    confirmed: boolean;
    releaseDate: Date;
    patient: PatientItem;
    validationController: ValidationController;
    i18n: I18N;

    dpOptions = {
        format: RuntimeInfo.DateTimeFormat,
        locale: RuntimeInfo.Language,
        showTodayButton: true,
        showClear: false,
        showClose: true,
        keepOpen: false,
        widgetPositioning: {
            horizontal: 'auto',
            vertical: 'auto'
        },
        focusOnShow: false
    };

    constructor(controller: DialogController, validationControllerFactory: ValidationControllerFactory, validator: Validator, i18n: I18N) {
        this.controller = controller;
        this.i18n = i18n;
        this.validationController = validationControllerFactory.createForCurrentScope(validator);
        let rulez = ValidationRules
            .ensure('confirmed').satisfies(o => typeof o === "boolean" && o === true).withMessage(this.i18n.tr('error_confirm_release_patient_final'))
            .ensure('releaseDate').satisfies(() => {
                if (!this.patient.encounter.period) {
                    this.patient.encounter.period = {};
                }
                if (!this.patient.encounter.period.start) return true;

                let start = moment(this.patient.encounter.period.start).toDate();
                let end = moment(this.releaseDate).toDate();
                return end.valueOf() > start.valueOf();
            }).withMessage(this.i18n.tr('release_date_before_admit_date'))
            .ensure('releaseDate').required().withMessage(this.i18n.tr('error_release_date_required'))
            .rules;

        this.validationController.addObject(this, rulez);
    }

    cancel() {
        this.controller.cancel();
    }

    submit() {
        RuntimeInfo.HideInfo();
        this.validationController.validate()
            .then(result => {
                if (!result.valid) {
                    let div = document.createElement('div');

                    let b = document.createElement('b');
                    b.style.display = 'block';
                    b.innerText = this.i18n.tr('validation_error_creating_patient');

                    let ul = document.createElement('ul');
                    ul.style.listStyle = 'decimal';
                    result.results.filter(o => o.valid === false).forEach(result => {
                        let li = document.createElement('li');
                        li.innerText = result.message;

                        ul.appendChild(li);
                    });

                    div.appendChild(b);
                    div.appendChild(ul);
                    RuntimeInfo.ShowInfo(div.outerHTML, false, () => RuntimeInfo.HideInfo());
                } else this.controller.ok(this.releaseDate);
            });
    }

    activate(model) {
        this.patient = model.patient;
    }
}
