import {FormBaseClass} from "../../resources/elements/FormBaseClass";
import {Questionnaire, QuestionnaireResponse} from "../../resources/classes/FhirModules/Fhir";
import {UserService} from "../../resources/services/UserService";
import {ConfigService} from "../../resources/services/ConfigService";

const moment = require('moment');

export class SubdelegationView extends FormBaseClass {
    _route = 'subdelegation';

    info : SubdelegationInfo;

    override async attached(): Promise<void> {
        await super.attached();

        if (ConfigService.Debug) window["subdelegation"] = this;
    }

    override async createButtonClicked(preset?): Promise<any> {
        const result = await super.createButtonClicked(preset);
        this.info = new SubdelegationInfo(result, this.questionnaire);
        return result;
    }

    override;

    override async afterResponseChanged(response: any) {
        await super.afterResponseChanged(response);

        this.info = new SubdelegationInfo(response, this.questionnaire);
    }
}

export class SubdelegationInfo {

    __groupsResponseItem : any;
    get affectedGroups() : string {
        return QuestionnaireResponse.GetResponseItemValue(this.__groupsResponseItem, undefined);
    }

    set affectedGroups(value : string) {
        this.__groupsResponseItem.answer = [{ valueString: value}];
    }

    __expirationResponseItem : any;
    get expiration() : Date {
        if (this.__expirationResponseItem?.answer)
            return moment(QuestionnaireResponse.GetResponseItemValue(this.__expirationResponseItem)).toDate();

        return undefined;
    }
    set expiration(value : Date) {
        if (value)
            this.__expirationResponseItem.answer = [{ valueDate: moment(value).toJSON() }];
        else
            delete this.__expirationResponseItem.answer;
    }


    __forUserIdResponseItem : any;
    get forUserId() : string {
        return QuestionnaireResponse.GetResponseItemValue(this.__forUserIdResponseItem);
    }
    set forUserId(value : string) {
        QuestionnaireResponse.SetResponseItemString(this.__forUserIdResponseItem, value);
    }


    __forUserDisplayResponseItem : any;
    get forUserDisplay() : string {
        return QuestionnaireResponse.GetResponseItemValue(this.__forUserDisplayResponseItem);
    }
    set forUserDisplay(value : string) {
        QuestionnaireResponse.SetResponseItemString(this.__forUserDisplayResponseItem, value);
    }

    __forUserQualificationsResponseItem : any;
    get forUserQualification() : string {
        return QuestionnaireResponse.GetResponseItemValue(this.__forUserQualificationsResponseItem);
    }
    set forUserQualification(value : string) {
        QuestionnaireResponse.SetResponseItemString(this.__forUserQualificationsResponseItem, value);
    }


    get responseId() {
        return this.sourceResponse?.id;
    }

    __fromUserIdResponseItem : any;
    get fromUserId() : string {
        return QuestionnaireResponse.GetResponseItemValue(this.__fromUserIdResponseItem);
    }
    set fromUserId(value : string) {
        QuestionnaireResponse.SetResponseItemString(this.__fromUserIdResponseItem, value);
    }


    __fromUserDisplayResponseItem : any;
    get fromUserDisplay() : string {
        return QuestionnaireResponse.GetResponseItemValue(this.__fromUserDisplayResponseItem);
    }
    set fromUserDisplay(value : string) {
         QuestionnaireResponse.SetResponseItemString(this.__fromUserDisplayResponseItem, value);
    }


    __forPractitionerIdResponseItem : any;
    get forPractitionerId() : string {
        return QuestionnaireResponse.GetResponseItemValue(this.__forPractitionerIdResponseItem);
    }
    set forPractitionerId(value : string) {
        QuestionnaireResponse.SetResponseItemString(this.__forPractitionerIdResponseItem, value);
    }

    constructor(protected sourceResponse : any, protected questionnaire : any) {
        if (!sourceResponse) return;

        this.__expirationResponseItem = QuestionnaireResponse.GetResponseItemByLinkId(sourceResponse, 'NIT_SD_ExpirationDate', true);
        this.__forUserIdResponseItem = QuestionnaireResponse.GetResponseItemByLinkId(sourceResponse, 'NIT_SD_ForUserId', true);
        this.__forUserDisplayResponseItem = QuestionnaireResponse.GetResponseItemByLinkId(sourceResponse, 'NIT_SD_ForUserName', true);
        this.__forUserQualificationsResponseItem = QuestionnaireResponse.GetResponseItemByLinkId(sourceResponse, 'NIT_SD_ForQualification', true);
        this.__fromUserIdResponseItem = QuestionnaireResponse.GetResponseItemByLinkId(sourceResponse, 'NIT_SD_GrantedByUserId', true);
        this.__fromUserDisplayResponseItem = QuestionnaireResponse.GetResponseItemByLinkId(sourceResponse, 'NIT_SD_GrantedByDisplay', true);
        this.__forPractitionerIdResponseItem = QuestionnaireResponse.GetResponseItemByLinkId(sourceResponse, 'NIT_SD_ForPractitionerId', true);
        this.__groupsResponseItem = QuestionnaireResponse.GetResponseItemByLinkId(sourceResponse, 'NIT_SD_AffectedGroups', true);

    //    this.validate();
    }

    get isValid() {
        return !!String(this.expiration) && !!this.fromUserDisplay && !!this.fromUserId &&
            !!this.forUserQualification && !!this.forUserDisplay && !!this.forUserId;
    }

    public static GetAffectedGroups(questionnaire : any, response : any) : string[] {
        if (!questionnaire || !response)
            return [];

        const result = [];
        const linkIds = Questionnaire.GetAllQuestionnaireItemLinkIds(questionnaire);
        for (const linkId of linkIds) {
            // all the group info(s) are stored in boolean fields. So we simply use them to get the linkIds for the

            const questionnaireItem = Questionnaire.GetQuestionnaireItemByLinkId(questionnaire, linkId);
            if (questionnaireItem && questionnaireItem.extension) {
                const groupIndexExtension = questionnaireItem.extension.find(o=>o.url.endsWith('subdelegation-group-index'));
                if (groupIndexExtension && typeof groupIndexExtension.valueInteger === "number") {
                    const responseItemValue = QuestionnaireResponse.GetResponseItemValueByLinkId(response, linkId, undefined);
                    if (responseItemValue === true) {
                        result.push(String(groupIndexExtension.valueInteger));
                    }
                }
            }
        }

        return result;
    }

    get isExpired() : boolean {
        return this.expiration ? moment(new Date()).isAfter(this.expiration) : false;
    }

    validate() {
        const subValidate = function(item : any, defaultValue : string|Date) {
            if (!item) return;
            if (item.answer && item.answer[0] && (item.answer[0].valueDate || item.answer[0].valueString))
                return;

            if (typeof(defaultValue === "string"))
                item.answer = [{ valueString: String(defaultValue) }];
            else {
                item.answer = [{ valueDate: moment(defaultValue).toJSON() }];
            }

            console.warn(`Updated UNSET field "${item.linkId}" with a default value of "${String(defaultValue)}" to be valid`);
        };

        subValidate(this.__fromUserIdResponseItem, UserService.UserName);
        subValidate(this.__fromUserDisplayResponseItem,  `${UserService.UserLastName}, ${UserService.UserFirstName}`);
        subValidate(this.__expirationResponseItem, moment(new Date()).add('90', 'days').toDate());
        subValidate(this.__forUserQualificationsResponseItem, 'PA');
    }
}
