import {autoinject, bindable} from "aurelia-framework";
import {DialogController, DialogService} from "aurelia-dialog";
import {I18N} from "aurelia-i18n";
import {RuntimeInfo} from "../../../resources/classes/RuntimeInfo";

const moment = require("moment");

@autoinject
export class WoundDateEdit {
    @bindable selectedDate;
    @bindable label;
    controller: DialogController;
    service: DialogService;
    i18n: I18N;
    dpOptions;
    mobileMaxDate;
    mobileInput: HTMLInputElement;

    getDPOptions(format: string, sideBySide: boolean, showToday: boolean = true) {
        return {
            sideBySide: sideBySide,
            icons: {
                time: "fa fa-clock-o",
                date: "fa fa-calendar",
                up: "fa fa-arrow-up",
                down: "fa fa-arrow-down",
                close: "fa fa-check",
                today: "fa fa-calendar-times-o"
            },
            locale: this.i18n.i18next.language,
            format: format,
            toolbarPlacement: 'bottom',
            showClear: true,
            showTodayButton: showToday,
            showClose: true,
            widgetPositioning: {horizontal: 'left'},
            inline: RuntimeInfo.IsMobile,
            maxDate: new Date()
        };
    }

    get curDate(): Date {
        if (RuntimeInfo.IsMobile && this.mobileInput) return moment(this.mobileInput.value).toJSON();

        return moment(this.selectedDate, RuntimeInfo.DateTimeFormat).toJSON();
    }

    constructor(controller: DialogController, service: DialogService, i18n: I18N) {
        this.controller = controller;
        this.service = service;
        this.i18n = i18n;

        this.controller.settings.centerHorizontalOnly = true;
    }

    get isMobile(): boolean {
        return RuntimeInfo.IsMobile;
    }

    /** check and - if needed - reset the date on mobile devices, as they don't react to max-value most times we have to do it manually */
    checkDate() {
        window.setTimeout(() => {
            let dCurrent = moment(this.mobileInput.value);
            let dMax = moment(new Date());
            if (dCurrent.isAfter(dMax)) {
                this.mobileInput.value = dMax.format("YYYY-MM-DDThh:mm");
            }
        }, 250);
    }

    activate(options) {
        this.selectedDate = moment(options.date).toDate();
        this.label = options.title;
        this.dpOptions = this.getDPOptions(RuntimeInfo.DateTimeFormat, this.isMobile, true);
        this.mobileMaxDate = moment(new Date()).format("YYYY-MM-DD");

        // when running on mobile..
        if (RuntimeInfo.IsMobile) {
            // wait for the DOM to be rendered..
            window.setTimeout(() => {
                // then set the datepicker to the selected date
                this.mobileInput.value = moment(this.selectedDate).format("YYYY-MM-DDThh:mm");
            }, 250);
        }
    }
}
